import React, { useState, useRef, useEffect } from 'react'
import * as style from '../styles/result_slider.module.css'

const data = [
  {
    headline:
      'Resolved Extraordinary Twists and Turns in Settlement of Will Contest',
    text: 'Successfully reallocated over $3,500,000.00 of an estate in settlement of a will contest commenced by our client asserting lack of testamentary capacity. Overcoming unusual procedural challenges, and understanding and obtaining critical pretrial testimony from medical professionals, proved instrumental in our ability to negotiate a favorable settlement.',
  },
  {
    headline: 'Recovered Portion of Marijuana Investment That Was Up in Smoke',
    text: 'Pursued and returned to our clients a large portion of the million-dollar plus investment made in an ongoing venture which owned medical and recreational marijuana licensed-entities in various Colorado communities. The matter involved lawsuits pending in two jurisdictions, and a favorable global settlement was reached for our clients without the necessity of a trial.',
  },
  {
    headline:
      'Uncovered Fraudulent Nondisclosure by Personal Representative of Estate',
    text: 'Prevailed against personal representative of an estate who engaged in lengthy defense of lawsuit prior to opening the estate, then asserted that judgment against estate was void, because estate was not opened in a timely manner. After a two-day trial, the court entered a personal judgment in excess of $450,000.00 against the former personal representative.',
  },
  {
    headline:
      "Rectified Financial Harm Caused by Attorney's Breach of Fiduciary Duty",
    text: "Successfully proved a breach of fiduciary duty by client's former attorney through a five-day trial, and recovered an LLC interest worth tens of millions of dollars, which our client had transferred to the attorney nearly a decade prior to the lawsuit. The discovery of relevant evidence through the attorney's trial testimony in a related criminal action, and the attorney's pretrial death, created challenging evidentiary hurdles which we overcame.",
  },
  {
    headline:
      'Exposed Fraudulent Conduct by Party Seeking Millions From Client',
    text: "After fourteen-day trial, court found that our client's electronic signature had been deceptively placed on a contract purporting to transfer over $20,000,000.00 to opposing party. Despite a prior acquittal of opposing party in criminal court, we established forgery, prevailed on civil conspiracy and other claims, and obtained $400,000 plus judgment for client.",
  },
  {
    headline:
      'Untangled Class Action in Federal Court for Alleged Lending Violations',
    text: "Negotiated settlement of complicated class action litigation alleging over 500 violations of the Federal Truth in Lending Act and the Colorado civil theft statute. By immersing ourselves in the applicable statutes and regulations, we demonstrated that claims were inappropriately asserted, and, on the eve of trial, received the court's assistance in obtaining a favorable settlement.",
  },
]

function Dot({ currentIndex, idx, setIndex }) {
  const updateIndex = () => setIndex(idx)

  return (
    <div
      className={style.dot}
      onClick={updateIndex}
      onKeyDown={e => e.code === 'Enter' && updateIndex()}
      role='button'
      tabIndex={0}
      style={{
        backgroundColor: currentIndex === idx ? 'var(--grey)' : 'white',
      }}
    >
      {''}
    </div>
  )
}

export default function ResultSlider() {
  const timerDuration = useRef(10000)
  const [index, setIndex] = useState(0)
  const [loaded, setLoaded] = useState(false)

  // Move to the next item, or wrap around to the first
  const incrementIndex = () => {
    setIndex(i => {
      const nextIndex = i + 1
      return nextIndex > data.length - 1 ? 0 : nextIndex
    })
  }

  // When index changes, clear the old timer and create a new one
  useEffect(() => {
    setLoaded(true)

    const indexTimer = setTimeout(incrementIndex, timerDuration.current)
    const fadeTimer = setTimeout(
      () => setLoaded(false),
      timerDuration.current - 500
    )

    return () => {
      clearTimeout(indexTimer)
      clearTimeout(fadeTimer)
    }
  }, [index])

  return (
    <div className={style.slider}>
      <span
        className={`${style.headline} ${loaded ? style.headline_visible : ''}`}
      >
        {data[index].headline}
      </span>

      <span className={`${style.text} ${loaded ? style.text_visible : ''}`}>
        {data[index].text}
      </span>

      <div className={style.dots}>
        {data.map((_, idx) => (
          <Dot key={idx} currentIndex={index} idx={idx} setIndex={setIndex} />
        ))}
      </div>
    </div>
  )
}
