import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page_title'
import ResultSlider from '../components/result_slider'
import PageContents from '../components/page_contents'
import * as style from '../styles/results.module.css'

export default function Results() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='Results'
        description='The Linden Law Group has a long track record of success, helping countless people through challenges just like yours. Click here to learn more.'
      />

      <PageContents>
        <PageTitle>Where We've Been</PageTitle>
        <div className={style.main}>
          <div className={`${style.section} ${style.left}`}>
            <span className={style.name}>Litigation</span>
            <span className={style.text}>
              Resolving a dispute through a jury or bench trial can be a
              lengthy, expensive, and stressful process, therefore, in some
              cases, litigation success takes the form of a settlement.
              Nevertheless, settlement is not always possible, despite the best
              efforts of the parties. Linden Law Group has delivered successful
              outcomes to our clients through both trials and settlements. These
              are just a few ...
            </span>

            <ResultSlider />
          </div>

          <div className={`${style.section} ${style.right}`}>
            <span className={style.name}>Liquor Licensing</span>
            <span className={style.text}>
              Recognizing the tremendous value of having a liquor license
              attorney who lives and practices law in their community, hundreds
              of the businesses in Colorado Springs and the surrounding
              localities are either long-standing or recently-engaged clients.
              Vince has also handled the liquor licensing, enforcement action
              defense, and other legal needs of a large number of local
              businesses and establishments located in Denver, Boulder, Pueblo,
              Fort Collins, and elsewhere in Colorado. These are just a few ...
            </span>

            <div className={style.photo_wrapper}>
              <div className={style.photo}></div>
            </div>
          </div>
        </div>
      </PageContents>
    </Layout>
  )
}
